@media (max-width: 349px) {
    .h-what {
      height: 85dvh;
    }

    .h-type {
        height: 40dvh;
    }

    .card {
        display: none;
        
    }
    .sadwomen {
        display: none;
    }

    .no-pd{
        margin-top: -3rem
    }

    .top {
        margin-top: 9rem;
    }

    /* type */
    .space {
        margin-top: 10dvh;
    }

    .btn {
        width: 25px;
        height: 30vh;
        background-color: rgba(34, 82, 159, 0.2);
        

    }
    .gap {
        grid-gap: 0.1rem; 
    }

    .card-type {
        width: 80%;

    }

    .no {
        display: none;
    }

    .h-type {
        margin-top: -5rem
    }

    /* Avenues */
    .no-p {
        padding: 0;
    }

    .p-title {
        padding-left: 10px;
    }

    /* diff */
    .text-size-title-diff {
        font-size: 19px;
    }

    /* signal */
    .h-signal {
        height: 85dvh;

    }

    /* from */
    .text-size-title-from {
        font-size: 14px;
    }

    /* agency */
    .text-size-title-agen {
        font-size: 19px;
    }
    
    .text-size-small-agen {
        font-size: 12px;
    }

    .card-hos {
        height: fit-content;
    }


  }
  
  
@media (max-width: 295px){
    .h-signal {
        height: 98dvh;
    }


 }

 @media (min-width: 638px) and (max-width: 765px) {
    .hide-el {
        display: none;
    }
    
 }
